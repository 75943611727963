import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Droppable } from 'react-drag-and-drop'

import { IGraphPeopleNode } from 'Features/GraphNodes/NodeTypes'
import { Chart, FontLoader, Link, Node } from 'regraph'

import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'

import GraphTooltip from 'Components/Blocks/Graph/GraphTooltip'

import { DRAG_DROP, KEYS } from 'Constants/ids'

import {
  useEntityModal,
  useEventBusSubscribe,
  useGraphContext,
  useResponsiveLayout,
  useWindowDimensions,
} from 'Hooks'

import EventBus from 'Services/EventBus'

import QuickActionBar, {
  QuickActionBarCoordinates,
} from './QuickActionBar/QuickActionBar'
import AddSkillTagPanel, {
  AddSkillTagFormValues,
} from './QuickActions/AddSkillTagPanel'
import QuickNotePanel from './QuickActions/QuickNotePanel'
import { GraphContext } from './ContextMenu'
import GraphControls from './GraphControls'
import OrganizationPanel from './OrganizationPanel'
import { GRAPH_ANIMATION_TIME, graphSettings } from './settings'
import { Container } from './styles'
import utils, { IItemData, IOrganizationNodeData } from './utils'

import 'Assets/Fonts/GraphIcons/GraphIcons.css'

enum PanelType {
  AddSkillTag = 'AddSkillTag',
  Note = 'Note',
}

interface Panel<TypeT, DataT> {
  type: TypeT
  data: DataT
}

type Panels =
  | Panel<PanelType.Note, IGraphPeopleNode>
  | Panel<PanelType.AddSkillTag, Partial<AddSkillTagFormValues>>

function Graph() {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const chartRef = useRef<Chart | null>(null)
  const [windowDimension] = useWindowDimensions()
  const { isMobile } = useResponsiveLayout()
  const [quickActionsCoordinates, setQuickActionsCoordinates] =
    useState<QuickActionBarCoordinates | null>(null)
  const [activePanel, setActivePanel] = useState<Panels | null>(null)
  const [viewOrganizationPanel, viewOrganizationActions] =
    useEntityModal<IOrganizationNodeData>()

  const {
    isLoading,
    isHandMode,
    useQuickActions,
    subGraphs,
    graphControls,
    graphState,
    showGraphMenu,
    graphHandler,
    graphMapper,
    availableQuickActions,
    setGraphState,
  } = useGraphContext()

  // TODO: move to quick action bar
  const handleQuickActionsCoordinates = useCallback(
    (selection?: Chart.Props['selection'], positions?: Chart.Positions) => {
      if (!chartRef.current) {
        return
      }

      // there doesn't appear to be a way to determine the bounding box of a node so the following offsets
      // are hardcoded to manual determine those values. The topOffsetY if the distance from the center of the node
      // to the top of the node and the bottomOffsetY is the distance from the center of the node to the bottom of the node
      const topOffsetY = 44
      const bottomOffsetY = 65

      // this is extra padding we add after the view coordinates have been calculated to give some space between the quick
      // action bar and the nodes
      const paddingY = 25

      // for each selected node we generate the top and bottom coordinates locations for the quick action bar
      const selectedTopCoordinates: QuickActionBarCoordinates[] = []
      const selectedBottomCoordinates: QuickActionBarCoordinates[] = []

      const selectedIds = Object.keys(selection ?? {})
      selectedIds.forEach(selectedId => {
        const item = graphState.items[selectedId]
        const position = positions?.[selectedId]

        if (!item || !position) {
          setQuickActionsCoordinates(null)

          return
        }

        // node size is used to multiply the offset value so the bounding box can scale
        const nodeSize = utils.getNodeSize(item)

        const topCoordinates = chartRef.current?.viewCoordinates(
          position.x,
          position.y - topOffsetY * nodeSize,
        )
        if (topCoordinates) {
          selectedTopCoordinates.push({
            x: topCoordinates.x,
            y: topCoordinates.y - paddingY,
          })
        }

        const bottomCoordinates = chartRef.current?.viewCoordinates(
          position.x,
          position.y + bottomOffsetY * nodeSize,
        )
        if (bottomCoordinates) {
          selectedBottomCoordinates.push({
            x: bottomCoordinates.x,
            y: bottomCoordinates.y + paddingY,
          })
        }
      })

      if (
        windowDimension?.height &&
        selectedTopCoordinates.length > 0 &&
        selectedBottomCoordinates.length > 0
      ) {
        // determine if we are using the top or bottom coordinates to place the quick action bar based on
        // which vertical half of the window has more space available when taking the coordinates into account
        const minYTopCoordinate = minBy(selectedTopCoordinates, 'y')!
        const maxYBottomCoordinate = maxBy(selectedBottomCoordinates, 'y')!
        const shouldUseTopCoordinates =
          windowDimension.height - maxYBottomCoordinate.y <= minYTopCoordinate.y

        const minXCoordinate = minBy(
          shouldUseTopCoordinates
            ? selectedTopCoordinates
            : selectedBottomCoordinates,
          'x',
        )!
        const maxXCoordinate = maxBy(
          shouldUseTopCoordinates
            ? selectedTopCoordinates
            : selectedBottomCoordinates,
          'x',
        )!

        // if the quick action bar is placed at the top of the nodes, we want to use the top most y coordinate
        // and if the quick action bar is placed at the bottom of the nodes, we want to use the bottom most y coordinate
        const yCoordinate = shouldUseTopCoordinates
          ? minBy(selectedTopCoordinates, 'y')!
          : maxBy(selectedBottomCoordinates, 'y')!

        // TODO: ensure that the coordinates are never out of bounds
        const quickActionCoordinates: QuickActionBarCoordinates = {
          // place the quick action bar in the middle of the selected nodes
          x: maxXCoordinate.x - (maxXCoordinate.x - minXCoordinate.x) / 2,
          y: yCoordinate.y,
        }

        setQuickActionsCoordinates(quickActionCoordinates)
      } else {
        // clear out the coordinates if no nodes are selected
        setQuickActionsCoordinates(null)
      }
    },
    [graphState.items, windowDimension],
  )

  const handleViewChange = useCallback<Chart.onViewChangeHandler>(() => {
    handleQuickActionsCoordinates(graphState.selection, graphState.positions)
  }, [
    handleQuickActionsCoordinates,
    graphState.selection,
    graphState.positions,
  ])

  useEffect(() => {
    handleQuickActionsCoordinates(graphState.selection, graphState.positions)
  }, [
    handleQuickActionsCoordinates,
    graphState.selection,
    graphState.positions,
  ])

  // TODO: can this be removed in favor of custom handler?
  useEffect(() => {
    containerRef.current?.addEventListener('contextmenu', (event: MouseEvent) =>
      event.preventDefault(),
    )
  }, [])

  const [shouldFocusNode, setShouldFocusNode] = useState(false)

  useEffect(() => {
    const nodeId = Object.keys(graphState.selection ?? {})[0]

    if (nodeId && shouldFocusNode) {
      setTimeout(() => {
        chartRef.current?.fit('selection')
        chartRef.current?.ping(nodeId, {
          color: 'rgba(205, 28, 69, 0.6)',
          repeat: 3,
          haloWidth: 16,
        })
        // wait for graph
        // animation to finish
      }, GRAPH_ANIMATION_TIME + 1)

      setShouldFocusNode(false)
    }
  }, [graphState.selection, shouldFocusNode])

  useEventBusSubscribe(
    EventBus.actions.dashboard.addTagsToUser,
    (data: Partial<AddSkillTagFormValues>) => {
      setActivePanel({
        type: PanelType.AddSkillTag,
        data,
      })
    },
  )

  useEventBusSubscribe(
    EventBus.actions.dashboard.createQuickNote,
    (user: IGraphPeopleNode) => {
      setActivePanel({
        type: PanelType.Note,
        data: user,
      })
    },
  )

  useEventBusSubscribe(EventBus.actions.graph.focusNode, nodeId => {
    setSelectedNode(nodeId)
  })

  const setSelectedNode = (nodeId: string) => {
    setGraphState(prevState => ({
      ...prevState,
      selection: { [nodeId]: true },
    }))
    setShouldFocusNode(true)
  }

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<Chart>) => {
      const hasModifierKey = event.metaKey || event.ctrlKey

      if (hasModifierKey && event.code === KEYS.UP) {
        chartRef.current?.zoom('in')
      }

      if (hasModifierKey && event.code === KEYS.DOWN) {
        chartRef.current?.zoom('out')
      }

      if (event.code === KEYS.BACKSPACE || event.code === KEYS.DELETE) {
        const selectedIds = Object.keys(graphState.selection ?? {})

        EventBus.trigger(EventBus.actions.graph.removeItemsByIds, selectedIds)
      }
    },
    [graphState.selection],
  )

  const handleCloseActivePanel = useCallback(() => {
    setActivePanel(null)
  }, [])

  useEventBusSubscribe(
    EventBus.actions.graph.clickItem,
    (item: Node<IItemData> | Link<IItemData>) => {
      if (!utils.isOrganizationNode(item)) {
        return
      }

      const organizationNode = utils.getAsOrganizationNode(item)

      viewOrganizationActions.openModal(organizationNode.data?.data)
    },
  )

  return (
    <Container mobile={isMobile} ref={containerRef}>
      <GraphControls
        graphControls={graphControls}
        isSubGraph={!!subGraphs.length}
        loading={isLoading}
      />

      {useQuickActions &&
        !graphHandler.isDragging &&
        !!quickActionsCoordinates && (
          <QuickActionBar
            availableQuickActions={availableQuickActions}
            coordinates={quickActionsCoordinates}
          />
        )}

      {activePanel && (
        <>
          {activePanel.type === PanelType.AddSkillTag && (
            <AddSkillTagPanel
              initialValues={activePanel.data}
              onClose={handleCloseActivePanel}
            />
          )}

          {activePanel.type === PanelType.Note && (
            <QuickNotePanel
              user={activePanel.data as MainSchema.GraphUser}
              onClose={handleCloseActivePanel}
            />
          )}
        </>
      )}

      {viewOrganizationPanel.isOpen && viewOrganizationPanel.entity && (
        <OrganizationPanel
          organization={viewOrganizationPanel.entity}
          onClose={() => viewOrganizationActions.closeModal(true)}
        />
      )}

      <Droppable
        style={{ height: '100%', width: '100%' }}
        types={[DRAG_DROP.USER]}
        onDrop={graphMapper.handleExternalDragDrop}
      >
        <FontLoader
          config={{
            google: { families: ['Inter:400,700'] },
            custom: { families: ['GraphIcons'] },
          }}
        >
          <Chart
            combine={graphState?.combine}
            items={graphState.items}
            layout={graphState.layout}
            positions={graphState.positions}
            ref={chartRef}
            selection={graphState.selection}
            onChange={graphHandler.handleChange}
            onClick={graphHandler.handleClick}
            onCombineLinks={graphHandler.handleCombineLinks}
            onCombineNodes={graphHandler.handleCombineNodes}
            onContextMenu={graphHandler.handleShowContextMenu}
            onDoubleClick={graphHandler.handleDoubleClick}
            onDragEnd={graphHandler.handleDragEnd}
            onDragOver={graphHandler.handleDragOver}
            onDragStart={graphHandler.handleDragStart}
            onHover={graphHandler.handleHover}
            onItemInteraction={graphHandler.handleItemInteraction}
            onKeyDown={handleOnKeyDown}
            onViewChange={handleViewChange}
            {...graphSettings({
              handMode: isHandMode,
              dragPan: true,
            })}
          />
        </FontLoader>
      </Droppable>

      <GraphContext
        isOpen={!!showGraphMenu}
        left={showGraphMenu?.x}
        top={showGraphMenu?.y}
        onRemoveSelected={graphMapper.handleRemoveSelected}
        onSelectedItems={graphMapper.handleSubgraphSelectedItems}
      />
      <GraphTooltip />
    </Container>
  )
}

export default memo(Graph)
