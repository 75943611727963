import { ApolloClient } from '@apollo/client'

// TODO: Create a new tags query specifically for loading tag data
import listTagsQuery from './Queries/graphTags.graphql'
import { IGraphCommunityUserTagNode } from './NodeTypes'

export interface ILoadGraphTagsResult {
  nodes: IGraphCommunityUserTagNode[]
  count: number
  pages: number
}

export interface ILoadGraphTagsArgs {
  communityIds: string[]
  kinds?: MainSchema.TagSearchKind
  kind?: MainSchema.TagKind
  limit?: number
  page?: number
  client?: ApolloClient<any>
}

export default async function LoadGraphTags(
  args: ILoadGraphTagsArgs,
): Promise<ILoadGraphTagsResult> {
  if (!args.client || !args.communityIds?.length) {
    return {
      nodes: [],
      count: 0,
      pages: 0,
    }
  }

  const result = await args.client.query<
    Pick<MainSchema.Query, 'listTags'>,
    MainSchema.QueryListTagsArgs
  >({
    query: listTagsQuery,
    variables: {
      communityId: args?.communityIds?.[0],
      kinds: args.kinds || undefined,
      kind: args.kind || undefined,
      limit: args.limit || 10,
      page: args.page || 0,
    },
  })

  const results = {
    nodes:
      result?.data?.listTags?.rows.map((tag: any) => ({
        tagId: tag.id,
        tag: {
          id: tag.id,
          name: tag.name,
          kind: tag.kind,
        },
        name: tag.name,
        kind: tag.kind,
      })) || [],
    count: result?.data?.listTags?.count || 0,
    pages: result?.data?.listTags?.pages || 0,
  }

  return results
}
