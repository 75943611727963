import { ACTION_KIND } from 'Constants/graph'

export const TOOLTIPS_BY_KIND = {
  [ACTION_KIND.skill]: 'Skill',
  [ACTION_KIND.role]: 'Role',
  [ACTION_KIND.group]: 'Group',
  [ACTION_KIND.project]: 'Project',
  [ACTION_KIND.event]: 'Event',
  [ACTION_KIND.custom]: 'Custom',
}
