import React, { useCallback, useMemo } from 'react'

import { IGraphPeopleNode } from 'Features/GraphNodes/NodeTypes'
import { IMentionedItem } from 'Features/Notes/utils'
import Utils from 'Utils'

import isEmpty from 'lodash/isEmpty'

import { Column, Divider, Row, Tooltip } from 'Components/UI'

import { TAG_COLOR_KIND } from 'Constants/tags'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'

import TagList from './TagList'
import { HandshakeIcon } from './UserTags.styles'

export interface IUserTagsProps {
  appendUsers: IMentionedItem[]
  connectedUsers: IGraphPeopleNode[]
  meetUserIds: string[]
  meetUsers: IMentionedItem[]
  mentionedUserIds: string[]
  targetUser?: IGraphPeopleNode
}

function UserTags({
  targetUser,
  connectedUsers = [],
  appendUsers = [],
  mentionedUserIds = [],
  meetUserIds = [],
  meetUsers = [],
}: IUserTagsProps) {
  const t = useScopedI18n('features.notes.components.noteTags.userTags')

  const connectedUsersWithMentions = useMemo(() => {
    const initialArray = [...connectedUsers]
    initialArray.forEach((item, index) => {
      if (
        item.id &&
        (meetUserIds.includes(item.id) || mentionedUserIds.includes(item.id))
      ) {
        initialArray.splice(index, 1)
        initialArray.unshift(item)
      }
    })
    return initialArray
  }, [connectedUsers, meetUserIds, mentionedUserIds])

  const canHandshake = useCallback(
    (user: IMentionedItem) =>
      targetUser?.id !== user?.id && user?.email && user?.email !== 'Private',
    [targetUser?.id],
  )

  const handleHandshakeClick = useCallback(
    (user: IMentionedItem) => {
      if (!canHandshake(user)) {
        return
      }

      EventBus.trigger(EventBus.actions.dashboard.introduceTo, [
        targetUser,
        user,
      ])
    },
    [canHandshake, targetUser],
  )

  const handleClick = (item: IMentionedItem | IGraphPeopleNode) => {
    EventBus.trigger(EventBus.actions.graph.addUserById, {
      userId: item.id,
    })
  }

  if (isEmpty(connectedUsers) && isEmpty(appendUsers) && isEmpty(meetUsers))
    return null

  return (
    <Column>
      {!isEmpty(connectedUsers) && (
        <>
          <TagList
            items={connectedUsersWithMentions.map(item => ({
              id: item.id!,
              name: Utils.User.getFullName(item),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(item),
            }))}
            label={t('alreadyConnectedUsers')}
          />
        </>
      )}

      {!isEmpty(appendUsers) && (
        <>
          <Divider mb={3} />

          <TagList
            items={appendUsers.map(item => ({
              id: item.id,
              name: Utils.User.getFullName(item),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(item),
            }))}
            label={t('mentionedUsers')}
          />
        </>
      )}

      {!isEmpty(meetUsers) && (
        <>
          <Divider mb={3} />

          <TagList
            items={meetUsers.map(item => ({
              id: item.id,
              name: Utils.User.getFullName(item),
              colorKind: TAG_COLOR_KIND.USER,
              onClick: () => handleClick(item),
              renderAfter: canHandshake(item) ? (
                <Tooltip
                  content={`${_('tips.introduceTo')} ${Utils.User.getFullName(
                    item,
                  )}`}
                >
                  <Row onClick={() => handleHandshakeClick(item)}>
                    <HandshakeIcon />
                  </Row>
                </Tooltip>
              ) : undefined,
            }))}
            label={t('shouldMeetUsers')}
          />
        </>
      )}
    </Column>
  )
}

export default UserTags
