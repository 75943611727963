import React, { useCallback, useMemo } from 'react'

import changeReportGetByUserId from 'GraphQL/Queries/ChangeReport/ChangeReportGetByUserId.graphql'

// import { useMutation } from '@apollo/client'
import { Button, Column, Loader, Text } from 'Components/UI'

import { useCommunityContext } from 'Hooks'

import { useQuery } from 'Services/Apollo'

// import toast from 'Services/Toast'
import Biography from './Biography'
import BulletBlock from './BulletBlock'
// import SocialRating from './SocialRating'
import { Container } from './styles'

export interface IChangeReportProps {
  user: MainSchema.CommunityUser
}

// TODO: Update text to use translation service
function ChangeReport({ user }: IChangeReportProps) {
  const { community } = useCommunityContext()

  // const [requestChangeReport] = useMutation<
  //   Pick<MainSchema.Mutation, 'requestChangeReport'>,
  //   MainSchema.MutationRequestChangeReportArgs
  // >(requestChangeReportMutation)

  const { data, loading } = useQuery<
    Pick<MainSchema.Query, 'changeReportGetByUserId'>,
    MainSchema.QueryChangeReportGetByUserIdArgs
  >(changeReportGetByUserId, {
    variables:
      user.userId && community?.id
        ? { userId: user.userId, communityId: community.id }
        : undefined,
    skip: !user.userId || !community?.id,
  })

  const changeReport = useMemo(() => {
    const report = data?.changeReportGetByUserId

    if (!report) return report

    return {
      ...report,
      bio: report?.bio?.split('\n')?.filter(str => str !== '') || [],
      sdgs: report?.sdgs?.split('::')?.filter(str => str !== '') || [],
    }
  }, [data?.changeReportGetByUserId])

  const handleRequestChangeReport = useCallback(async () => {
    // TODO: Temporary solution from The Difference
    window.open(
      'https://collaborationai.zendesk.com/hc/en-us/requests/new?ticket_form_id=12907006919703',
    )

    /*
    try {
      await requestChangeReport({
        variables: {
          communityId: community?.id,
          userId: user.userId,
        },
      })

      // Refect to load requested status and prevent clicking twice
      await refetch()

      toast.success({
        title: 'Change Report',
        text: 'Successfully Requested Change Report',
      })

    } catch (error) {
      toast.error({
        title: 'Change Report',
        text: error?.message,
      })
    }
    */
  }, [])

  const noChangeReportInfo = `${
    user.firstName || 'N/A'
  }'s Change Report would be created by mining 100+
  public websites to create an index of weighted terms from ${
    user.firstName || 'N/A'
  }'s online activity. It can take up to 24-48 hours for our AI to
  complete the process.`

  if (loading) {
    return (
      <Column center fullHeight fullWidth justifyCenter>
        <Loader />
      </Column>
    )
  }

  if (!changeReport) {
    return (
      <>
        <Text header header4 mb={4}>
          We were unable to find a change report for {user.firstName}, you may
          request one by clicking below.
        </Text>
        <Button mb={4} small width={1} onClick={handleRequestChangeReport}>
          Request a Change Report
        </Button>
        <Text body bodyMedium>
          {noChangeReportInfo}
        </Text>
      </>
    )
  }

  return (
    <Container>
      {/* @TODO: */}
      {/* {(changeReport?.socialRating || !!changeReport?.sdgs?.length) && (
        <SocialRating
          sdgs={changeReport.sdgs}
          socialRating={changeReport.socialRating}
          user={user}
        />
      )} */}
      {changeReport?.bio && <Biography bio={changeReport.bio} user={user} />}
      {changeReport?.personalitySummary && (
        <BulletBlock
          header="Personality Summary"
          summary={changeReport.personalitySummary}
        />
      )}
      {changeReport?.overview1 && !!changeReport?.comesNaturally1?.length && (
        <BulletBlock
          header="Overview"
          items={changeReport.comesNaturally1.split('~')}
          subHeader={`When speaking to ${user.firstName}`}
          summary={changeReport.overview1}
        />
      )}
      {changeReport?.communication2 && !!changeReport?.speakingTo2?.length && (
        <BulletBlock
          header="Communication"
          items={changeReport.speakingTo2?.split('~')}
          subHeader={`When speaking to ${user.firstName}`}
          summary={changeReport.communication2}
        />
      )}
      {changeReport?.motivation3 && !!changeReport?.mostMotivate3?.length && (
        <BulletBlock
          header="Motivation"
          items={changeReport.mostMotivate3?.split('~')}
          subHeader={`${user.firstName} is most motivated by`}
          summary={changeReport.motivation3}
        />
      )}
      {changeReport?.work4 && !!changeReport?.workingWith4?.length && (
        <BulletBlock
          header="Work"
          items={changeReport.workingWith4?.split('~')}
          subHeader={`When working with ${user.firstName}`}
          summary={changeReport.work4}
        />
      )}
      {!!changeReport?.keyWordsChangeFromSkills?.length && (
        <BulletBlock
          header="Virtual Fingerprint"
          items={changeReport.keyWordsChangeFromSkills?.split('::')}
          summary={`${user.firstName}'s Fingerprint is created by mining 100+ public websites to create an index of weighted terms from ${user.firstName}'s online activity.`}
        />
      )}
      {!!changeReport?.keywords?.length && (
        <BulletBlock
          header="Keywords"
          items={changeReport?.keywords.split('::')}
        />
      )}
    </Container>
  )
}

export default ChangeReport
