import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'

import LoadGraphPeople, {
  ILoadGraphPeopleArgs,
  ILoadGraphPeopleResult,
} from './LoadGraphPeople'
import LoadGraphTags, { ILoadGraphTagsArgs } from './LoadGraphTags'
import LoadRelationshipEdges from './LoadRelationshipEdges'

export const useGraphLoaders = () => {
  const client = useApolloClient()
  const { featureEnabled } = useFeatureFlag()

  const loadPeople = useCallback(
    async (args: ILoadGraphPeopleArgs): Promise<ILoadGraphPeopleResult> => {
      const isGraphPeopleEnabled = await featureEnabled('graph_people')
      return LoadGraphPeople({
        ...args,
        useLegacy: !isGraphPeopleEnabled,
        client,
      })
    },
    [client, featureEnabled],
  )

  const loadTags = useCallback(
    async (args: ILoadGraphTagsArgs) => {
      return LoadGraphTags({
        ...args,
        client,
      })
    },
    [client],
  )

  const loadRelationshipEdges = useCallback(
    async (args: ILoadGraphTagsArgs) => {
      return LoadRelationshipEdges({
        ...args,
        client,
      })
    },
    [client],
  )

  return { loadPeople, loadTags, loadRelationshipEdges }
}
