import { ApolloClient } from '@apollo/client'
import communitySearchUsersQuery from 'GraphQL/Queries/Community/communitySearchUsers.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.min.graphql'

import { SortInputOrder } from 'Constants/mainGraphQL'

import graphUsersQuery from './Queries/graphPeople.graphql'
import {
  IGraphCommunityUserSkillNode,
  IGraphCommunityUserTagNode,
  IGraphConnectedPeopleNode,
  IGraphPeopleNode,
} from './NodeTypes'

export interface ILoadGraphPeopleResult {
  nodes: IGraphPeopleNode[]
  count: number
  pages: number
}

export interface ILoadGraphPeopleArgs {
  communityIds: string[]
  userIds?: string[]
  skillIds?: string[]
  tagIds?: string[]
  workHistoryJobTitleIds?: string[]
  workHistoryOrganizationIds?: string[]
  educationHistoryOrganizationIds?: string[]
  limit?: number
  page?: number
  useLegacy?: boolean
  useLegacyBackgroundLoader?: boolean
  client?: ApolloClient<any>
  forceReload?: boolean
}

export default async function LoadGraphPeople(
  args: ILoadGraphPeopleArgs,
): Promise<ILoadGraphPeopleResult> {
  if (!args.client || !args.communityIds || args.communityIds.length === 0) {
    return {
      nodes: [],
      count: 0,
      pages: 0,
    }
  }

  // Use legacy loaders
  if (args.useLegacy) return legacy(args)

  const result = await args.client.query<
    Pick<MainSchema.Query, 'graphPeople'>,
    MainSchema.QueryGraphPeopleArgs
  >({
    query: graphUsersQuery,
    fetchPolicy: args.forceReload ? 'network-only' : 'cache-first',
    variables: {
      communityIds: args?.communityIds,
      userIds: args?.userIds,
      skillIds: args?.skillIds,
      tagIds: args?.tagIds,
      workHistoryJobTitleIds: args?.workHistoryJobTitleIds,
      workHistoryOrganizationIds: args?.workHistoryOrganizationIds,
      educationHistoryOrganizationIds: args?.educationHistoryOrganizationIds,
      limit: args?.limit,
      page: args?.page,
      orderBy: { order: 'updatedAt', direction: 'DESC' },
    },
  })

  return result.data.graphPeople as ILoadGraphPeopleResult
}

// Delete me after feature is accepted
async function legacy(
  args: ILoadGraphPeopleArgs,
): Promise<ILoadGraphPeopleResult> {
  let userIds: string[] = []
  let { limit, page } = args

  // Use legacy background loader via listCommunityUsers query
  if (args.useLegacyBackgroundLoader) {
    const top25Results = await args?.client?.query<
      Pick<MainSchema.Query, 'listCommunityUsers'>,
      MainSchema.QueryListCommunityUsersArgs
    >({
      query: listCommunityUsersQuery,
      fetchPolicy: args.forceReload ? 'network-only' : 'cache-first',
      variables: {
        communityId: args.communityIds[0],
        limit: 25,
        page: 0,
        sortBy: [
          {
            column: 'createdAt',
            order: SortInputOrder.Desc,
          },
        ],
      },
    })

    userIds =
      top25Results?.data?.listCommunityUsers?.communityUsers?.map(
        user => user.userId,
      ) || []

    limit = userIds.length
    page = 0
  }

  const legacyData = await args?.client?.query<
    Pick<MainSchema.Query, 'communitySearch'>,
    MainSchema.QueryCommunitySearchArgs
  >({
    query: communitySearchUsersQuery,
    fetchPolicy: args.forceReload ? 'network-only' : 'cache-first',
    variables: {
      communityId: args.communityIds[0],
      users: userIds.length ? userIds : args?.userIds || [],
      skills: args?.skillIds || [],
      tags: args?.tagIds || [],
      organizations: args?.workHistoryOrganizationIds || [],
      page,
      limit,
    },
  })

  // Map to new format
  if (legacyData?.data?.communitySearch?.users) {
    const result = {
      nodes:
        legacyData?.data?.communitySearch.users?.map(user => {
          return {
            ...user,
            id: user.id,
            userId: user.userId,
            communityId: args.communityIds[0],
            communityUserId: user.communityUserId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            photoUrl: user.photoUrl,
            communityUserTags:
              user.tags?.map(tag => {
                return {
                  tagId: tag.id,
                  tag: {
                    id: tag.id,
                    name: tag?.name,
                    kind: tag?.kind,
                  },
                } as IGraphCommunityUserTagNode
              }) || [],
            communityUserSkills:
              user.skills?.map(skill => {
                return {
                  skillId: skill.id,
                  skill: {
                    id: skill.id,
                    name: skill?.name,
                  },
                } as IGraphCommunityUserSkillNode
              }) || [],
            communityUserWorkHistory:
              user.workHistory?.map(workHistory => {
                return {
                  organizationId: workHistory.organization?.id,
                  jobTitleId: workHistory.jobTitle?.id,
                } as any
              }) || [],
            communityUserEducationHistory:
              user.educationHistory?.map(educationHistory => {
                return {
                  organizationId: educationHistory.organization?.id,
                } as any
              }) || [],
            connections:
              Object.keys(user.connectedUsers).map((toUserId: string) => {
                return {
                  toUserId,
                } as IGraphConnectedPeopleNode
              }) || [],
          } as IGraphPeopleNode
        }) || [],
      count: legacyData?.data?.communitySearch.count,
      pages: legacyData?.data?.communitySearch.pages,
    }

    return result
  }

  return {
    nodes: [],
    count: 0,
    pages: 0,
  }
}
